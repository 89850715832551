/* eslint-disable radix */
import Header from 'component/global/Header/Header';
import React, { useEffect } from 'react';
import useResponsive from 'hooks/useResponsive';
import LoadingSpinner from 'component/LoadingSpinner';

import Map from 'component/Map/Map';
import { getAvailableCapsuleLocations } from 'providers/capsuleLocation';
import FailedImage from 'assets/img/failed.png';
import Footer from 'component/global/Footer/Footer';
import { useTranslation } from 'react-i18next';
import * as style from './BookingLocationStyle';
import ListLocation from './ListLocation';

type MapCoordinates = [number, number];

const BookingLocation: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('title.capsuleChoice');
  }, []);
  // TODO: add filter button etc ?
  const screenSize = useResponsive();
  const [loading, setLoading] = React.useState(false);
  const [bookingType, setBookingType] = React.useState('list');
  const [userCoordinates, setUserCoordinates] = React.useState<MapCoordinates>([
    48.8566, 2.3522,
  ]);
  const [locations, setLocations] = React.useState([]); // [latitude, longitude
  const getCoordinates = () => {
    // Vérifier la géolocalisation du navigateur
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setUserCoordinates([latitude, longitude]);
        },
        (error) => {
          console.error('Erreur de géolocalisation :', error);
        },
      );
    } else {
      console.error(
        "La géolocalisation n'est pas prise en charge par ce navigateur.",
      );
    }
  };
  useEffect(() => {
    setLoading(true);
    getCoordinates();

    getAvailableCapsuleLocations()
      .then((data) => {
        setLocations(data.data.locations);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }, [navigator.geolocation]);

  return (
    <style.Container>
      <Header title="Book" subTitle="capsule" />
      {loading ? (
        <LoadingSpinner shouldBeCenter />
      ) : (
        <>
          <style.Booking>
            <style.BookingListType size={screenSize}>
              <style.BookingListTypeButton
                size={screenSize}
                onClick={() => setBookingType('list')}
                type="button"
                selected={bookingType === 'list'}
              >
                {t('booking.location.list')}
              </style.BookingListTypeButton>
              <style.BookingListTypeButton
                size={screenSize}
                onClick={() => setBookingType('map')}
                type="button"
                selected={bookingType === 'map'}
              >
                {t('booking.location.map')}
              </style.BookingListTypeButton>
            </style.BookingListType>

            <style.BookingLocation size={screenSize}>
              {bookingType === 'map' ? (
                <Map
                  position={userCoordinates}
                  markers={locations}
                  css={{
                    margin: screenSize.isMobile ? '10% auto ' : '2% auto',
                    width: screenSize.isMobile ? '100%' : '70%',
                  }}
                />
              ) : (
                <ListLocation locations={locations} />
              )}
            </style.BookingLocation>
          </style.Booking>
          <Footer title="" />
        </>
      )}
    </style.Container>
  );
};

export default BookingLocation;
