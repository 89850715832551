import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useResponsive from 'hooks/useResponsive';
import * as style from './BookingPaymentSuccessStyle';

const BookingPaymentSuccess: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screenSize = useResponsive();
  const [countdown, setCountdown] = useState<number>(5);
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id',
  );

  useEffect(() => {
    document.title = t('title.paymentSuccess');

    if (!sessionId) {
      navigate('/');
      return;
    }

    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/booking');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const progress = countdown / 10;

  return (
    <style.Container size={screenSize}>
      <style.SuccessCard size={screenSize}>
        <style.Title size={screenSize}>{t('booking.payment.success.title')}</style.Title>
        <style.Message size={screenSize}>{t('booking.payment.success.message')}</style.Message>
        <style.Message size={screenSize}>
          {t('booking.payment.success.redirect')}
          {' '}
          {countdown}
          {' '}
          {t('booking.payment.success.seconds')}
        </style.Message>
        <style.CountdownWrapper>
          <style.Circle progress={progress} />
          <style.CountDown size={screenSize}>{countdown}</style.CountDown>
        </style.CountdownWrapper>
      </style.SuccessCard>
    </style.Container>
  );
};

export default BookingPaymentSuccess;
