export default {
  book: 'Book your reservation',
  booking: 'Book',
  'booking.choose': 'Choose a capsule',
  'booking.from': 'From',
  'booking.to': 'To',
  'booking.price.free': 'Offered by ',
  'booking.add': 'Add a booking',
  'booking.next': 'Next',
  'booking.previous': 'Previous',
  'booking.upcoming': 'Upcoming',
  'booking.reservationFor': 'Reservation for',
  'booking.confirmed': 'Booking confirmed!',
  'booking.coupon.currency.mismatch': 'The coupon is not valid for this capsule',
  'booking.passed': 'Booking passed. Thank you for your visit!',
  'booking.info': 'This is your unique pass for your capsule.',
  'booking.safe': "Don't share it and keep it safe!",
  'booking.download': 'Download your QR Code',
  'booking.download.step': 'save',
  'booking.location.map': 'Map',
  'booking.location.list': 'List',
  'booking.date.select': 'Select a date',
  'booking.free.title': 'Free session!',
  'booking.free.subtitle': 'Offered by ',
  'booking.payment.coupon': 'Enter your coupon',
  'booking.payment.submit': 'Pay now',
  'booking.payment.coupon.required': 'Coupon is required',
  'booking.coupon.notfound': 'Invalid coupon',
  'booking.coupon.notenough': 'Not enough amount',
  'booking.payment.coupon.question': 'Do you have a coupon?',
  'booking.payment.coupon.yes': 'Yes',
  'booking.payment.coupon.no': 'No',
  'booking.coupon.usedup': 'This coupon has been used too many times',
  'booking.payment.success.title': 'Payment successful!',
  'booking.payment.success.message': 'Congratulations! Your booking has been successfully confirmed.',
  'booking.payment.success.redirect': 'You will be redirected in',
  'booking.payment.success.seconds': 'seconds',
  'title.paymentSuccess': 'Payment successful',
};
