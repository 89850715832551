export default {
  'title.collection': 'silkke - Collection',
  'title.movie': 'silkke - Films',
  'title.movieDetails': 'silkke - Film',
  'title.fastPass': 'silkke - Fast pass',
  'title.login': 'silkke - Connexion',
  'title.preregister': 'silkke - Pré-inscription',
  'title.register': 'silkke - Inscription',
  'title.resetPassword': 'silkke - Mot de passe oublié',
  'title.dateBooking': 'silkke - Réservation',
  'title.yourBooking': 'silkke - Réservation',
  'title.bookingList': 'silkke - Réservations',
  'title.freeBooking': 'silkke - Réservation',
  'title.payment': 'silkke - Paiement',
  'title.capsuleChoice': 'silkke - Réservation',
  'title.settings': 'silkke - Paramètres',
  'title.bookingDate': 'silkke - Date de réservation',
};
