import COLORS from 'color';
import styled from 'styled-components';
import { ComponentProps } from 'type/ComponentProps';
import { ScreenSize } from 'type/ScreenSize';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.GAINSBORO};
  padding: 2rem;
`;

export const Title = styled.h1`
  color: ${COLORS.BLUE_SILKKE};
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  margin-bottom: 2rem;
`;

export const Form = styled.form<{ size?: ScreenSize }>`
  height: auto;
  width: 100%;
  max-width: ${({ size }) => (size?.isMobile ? '90%' : '500px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

export const FormInput = styled.input.attrs((props: { size?: ScreenSize }) => ({
  size: props.size,
}))`
  background: white;
  border: 2px solid ${COLORS.BLUE_SILKKE};
  height: 3.5rem;
  width: 100%;
  max-width: ${({ size }) => (size?.isMobile ? '80%' : '400px')};
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  padding: 0 1.5rem;
  font-size: ${({ size }) => (size?.isMobile ? '1rem' : '1.2rem')};
  font-family: "Urbanist", sans-serif;
  color: ${COLORS.GREY};
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${COLORS.BLUE_SILKKE_DARK};
    box-shadow: 0px 4px 15px rgba(69, 78, 206, 0.2);
  }

  ::placeholder {
    color: ${COLORS.GREY};
    font-size: ${({ size }) => (size?.isMobile ? '0.9rem' : '1.1rem')};
    font-family: "Urbanist", sans-serif;
  }

  @media (max-width: 768px) {
    width: 90%;
    max-width: 90%;
    font-size: 1rem;
    height: 3.2rem;
    padding: 0 1rem;
    margin: 0 auto;

    ::placeholder {
      font-size: 0.9rem;
    }
  }
`;

export const FormGroupInput = styled.div<{ size?: ScreenSize }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ size }) => (size?.isMobile ? '0 1rem' : '0')};
`;

export const FormButton = styled.button`
  background: ${COLORS.BLUE_SILKKE};
  color: white;
  font-size: 1.2rem;
  font-family: "Urbanist", sans-serif;
  font-weight: 500;
  padding: 1rem 2.5rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${COLORS.BLUE_SILKKE_DARK};
    transform: translateY(-2px);
    box-shadow: 0px 6px 15px rgba(69, 78, 206, 0.3);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const CouponQuestion = styled.p`
  font-size: 1.5rem;
  color: ${COLORS.BLUE_SILKKE};
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  font-family: "Urbanist", sans-serif;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 2.5rem;
  margin: 1rem 0 2rem;
  justify-content: center;
  flex-wrap: wrap;
`;

export const RadioWrapper = styled.div`
  position: relative;
  min-width: 150px;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

export const RadioLabel = styled.label<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem 1rem 3.5rem;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: "Urbanist", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
  color: ${COLORS.BLUE_SILKKE};
  border: 2px solid
    ${(props) => (props.isSelected ? COLORS.BLUE_SILKKE : '#E0E0E0')};
  position: relative;
  user-select: none;

  &:before {
    content: "";
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid
      ${(props) => (props.isSelected ? COLORS.BLUE_SILKKE : '#E0E0E0')};
    background: white;
    transition: all 0.2s ease;
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(1rem + 6px);
    top: 50%;
    transform: translateY(-50%) scale(${(props) => (props.isSelected ? 1 : 0)});
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${COLORS.BLUE_SILKKE};
    transition: all 0.2s ease;
  }

  &:hover {
    background: ${(props) => (props.isSelected ? 'white' : '#F8F9FF')};
    border-color: ${COLORS.BLUE_SILKKE};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(69, 78, 206, 0.15);

    &:before {
      border-color: ${COLORS.BLUE_SILKKE};
    }
  }

  ${RadioInput}:focus + & {
    box-shadow: 0 0 0 3px rgba(69, 78, 206, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.8rem 1.2rem 0.8rem 3rem;
    font-size: 1rem;

    &:before {
      width: 20px;
      height: 20px;
    }

    &:after {
      width: 14px;
      height: 14px;
      left: calc(1rem + 5px);
    }
  }
`;

export const CouponInputContainer = styled.div<{ isVisible: boolean }>`
  max-height: ${(props) => (props.isVisible ? '100px' : '0')};
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  justify-content: center;
`;
