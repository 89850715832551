import React, { useEffect, useState } from 'react';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';
import { set, useForm } from 'react-hook-form';
import LoadingSpinner from 'component/LoadingSpinner';
import { preparePaidBooking } from 'providers/booking';
import Notification from 'component/Notification';
import * as style from './BookingPaymentFormStyle';

const BookingPaymentForm: React.FC<{ capsule: any }> = ({ capsule }) => {
  const screenSize = useResponsive();
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('title.payment');
  }, []);

  const date = new URLSearchParams(window.location.search).get('date');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const priceFormatted = parseFloat(capsule.price).toFixed(2);
  const formattedPrice = priceFormatted.endsWith('.00')
    ? priceFormatted.slice(0, -3)
    : priceFormatted;

  const getCurrencySymbol = (currency: string) => {
    switch (currency?.toUpperCase()) {
      case 'EUR':
        return '€';
      case 'USD':
        return '$US';
      default:
        return '€'; // Valeur par défaut
    }
  };

  const randomKeyString = Math.random().toString(36).substring(7);

  const submit = (data: any) => {
    setLoading(true);
    if (errors.coupon) {
      setErrorMessage(t('booking.payment.coupon.required'));
      setLoading(false);
      return;
    }

    if (date) {
      preparePaidBooking(capsule.idCapsuleLocation, date, data.coupon || '')
        .then((response: any) => {
          if (response.data.booking) {
            const fastpass = new URLSearchParams(window.location.search).get(
              'fastpass',
            );
            const href = `/booking/${response.data.booking.idBooking}${
              fastpass === 'true' ? '?fastpass=true' : ''
            }`;
            window.location.href = href;
          } else {
            window.location.href = response.data.url;
          }
          setLoading(false);
        })
        .catch((error: any) => {
          setErrorMessage(t(error.response.data.message));
          setLoading(false);
        });
    }
  };

  return (
    <style.Container>
      <style.Form size={screenSize}>
        <style.Title>
          {formattedPrice}
          {' '}
          {getCurrencySymbol(capsule.currency)}
        </style.Title>

        <style.CouponQuestion>
          {t('booking.payment.coupon.question')}
        </style.CouponQuestion>
        <style.ButtonGroup>
          <style.RadioWrapper>
            <style.RadioInput
              name="hasCoupon"
              value="yes"
              checked={showCoupon}
              onChange={() => setShowCoupon(true)}
            />
            <style.RadioLabel isSelected={showCoupon}>
              {t('booking.payment.coupon.yes')}
            </style.RadioLabel>
          </style.RadioWrapper>
          <style.RadioWrapper>
            <style.RadioInput
              name="hasCoupon"
              value="no"
              checked={!showCoupon}
              onChange={() => setShowCoupon(false)}
            />
            <style.RadioLabel isSelected={!showCoupon}>
              {t('booking.payment.coupon.no')}
            </style.RadioLabel>
          </style.RadioWrapper>
        </style.ButtonGroup>

        <style.CouponInputContainer isVisible={showCoupon}>
          <style.FormGroupInput size={screenSize}>
            <style.FormInput
              size={screenSize}
              {...register('coupon', {
                required: showCoupon,
              })}
              placeholder={t('booking.payment.coupon')}
            />
          </style.FormGroupInput>
        </style.CouponInputContainer>

        {loading ? (
          <LoadingSpinner />
        ) : (
          <style.FormButton onClick={handleSubmit(submit)}>
            {t('booking.payment.submit')}
          </style.FormButton>
        )}
      </style.Form>
      {errorMessage && (
        <Notification
          key={randomKeyString}
          message={errorMessage}
          type="error"
        />
      )}
    </style.Container>
  );
};

export default BookingPaymentForm;
