export default {
  book: 'Réservez votre session',
  booking: 'Réserver',
  'booking.choose': 'Choisissez une capsule',
  'booking.from': 'Du',
  'booking.to': 'au',
  'booking.price.free': 'offert',
  'booking.reservationFor': 'Réservation pour le',
  'booking.add': 'Ajouter une réservation',
  'booking.next': 'Suivant',
  'booking.previous': 'Précédent',
  'booking.coupon.currency.mismatch': 'Le coupon n\'est pas valide pour cette capsule',
  'booking.upcoming': 'À venir',
  'booking.confirmed': 'Réservation confirmée !',
  'booking.passed': 'Réservation passée. Merci pour votre visite !',
  'booking.info': 'Ceci est votre pass unique pour votre capsule.',
  'booking.safe': 'Ne le partagez pas et gardez-le en sécurité !',
  'booking.download': 'Téléchargez votre QR Code',
  'booking.download.step': 'Enregistrement',
  'booking.location.map': 'Carte',
  'booking.location.list': 'Liste',
  'booking.date.select': 'Sélectionnez une date',
  'booking.free.title': 'Session gratuite !',
  'booking.free.subtitle': 'Offerte par ',
  'booking.payment.coupon': 'Entrez votre coupon',
  'booking.payment.submit': 'Payer maintenant',
  'booking.payment.coupon.required': 'Le coupon est requis',
  'booking.coupon.notfound': 'Coupon invalide',
  'booking.coupon.notenough': 'Montant insuffisant',
  'booking.payment.success.title': 'Paiement réussi !',
  'booking.payment.success.message': 'Félicitations ! Votre réservation a été confirmée avec succès.',
  'booking.payment.success.redirect': 'Vous serez redirigé dans',
  'booking.payment.success.seconds': 'secondes',
  'title.paymentSuccess': 'Paiement réussi',
  'booking.payment.coupon.question': 'Avez-vous un coupon ?',
  'booking.payment.coupon.yes': 'Oui',
  'booking.payment.coupon.no': 'Non',
  'booking.coupon.usedup': 'Ce coupon à été utilisé trop de fois',
};
